import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import './style.scss';

class Why extends Component {
  render() {
    return (
      <div className="pst-why">
        <div className="pst-why__title">Почемуша</div>
        <div className="pst-why__text"><strong>ответит</strong> с заботой на любой вопрос любимого ребенка</div>
        <button className="pst-why__btn" onClick={() => this.props.toggleChat()}>задать вопрос</button>
      </div>
    );
  }
}

Why.propTypes = {
  toggleChat: PropTypes.func,
};

export default withTranslation()(Why);
