import React, { useState } from 'react';
import { RiMenuAddLine } from 'react-icons/ri';

import TrackInfo from './TrackInfo';
import Controls from './Controls';
import ProgressBar from './ProgressBar';
import VolumeControl from './VolumeControl';
import PlayList from './PlayList';

const AudioPlayer = () => {
  const [openDrawer, setOpenDrawer] = useState(true);
  return (
    <React.Fragment>
      <div
        className="flex-shrink-0 min-h-8 flex flex-col gap-9 lg:flex-row justify-between items-center p-[0.5rem_10px]">
        <TrackInfo/>
        <div className="w-full flex flex-col items-center gap-1 m-auto flex-1">
          <Controls/>
          <ProgressBar/>
        </div>
        <div className="flex items-center gap-2 text-gray-400">
          <VolumeControl/>
          <button onClick={() => setOpenDrawer((prev) => !prev)}>
            <RiMenuAddLine/>
          </button>
        </div>
      </div>

      <div
        className={`transition-max-height duration-300 ease-in-out overflow-y-auto ${
          openDrawer ? '' : 'max-h-0'
        }`}
      >
        <PlayList/>
      </div>
    </React.Fragment>
  );
};

export default AudioPlayer;
