import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Collapser from '@app/components/NEW_ui/Collapser';
import CardBrief from '@app/components/NEW_ui/CardBrief/index';
import Button from '@app/components/NEW_ui/Button/index';
import Icon from '@app/components/NEW_ui/Icon/index';
import Text from '@app/components/NEW_ui/Text';

import MixpanelService from '@app/services/MixpanelService';
import mixpanelMap from '@app/mixpanelMap';
import genderMap from '@app/store/constants/genderMap';

import './style.scss';
import isRussianLanguage from '../../../../helpers/isRussianLanguage';

class NewPresentation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGender: '1',
    };
  }

  componentDidMount() {
    const dashboardGender = localStorage.getItem('dashboardGender');

    if (['0', '1'].includes(dashboardGender)) {
      this.setState({
        selectedGender: dashboardGender,
      });
    }
  }

  handleFilterTag = (title) => {
    if (!this.props.selectedTags.includes(title)) {
      this.props.addSelectedTagFilter(title);
    } else {
      this.props.removeSelectedTagFilter(title);
    }
  };

  handleClickPresentation = (topic) => {
    const { handleSelectedPresentation } = this.props;
    handleSelectedPresentation(topic);
  };

  handleFilterGender = (numGender) => {
    const { selectedGender } = this.state;
    const genderName = genderMap[numGender];

    if (selectedGender !== numGender) {
      this.setState({
        selectedGender: numGender,
      });
      localStorage.setItem('dashboardGender', numGender);

      // mixpanel tracking
      MixpanelService.track(`${mixpanelMap.CHANGE_GENDER} ${genderName}`, {
        genderName,
      });
    }
  };

  render() {
    const { dashboardList, selectedTags, tags, t } = this.props;
    const { selectedGender } = this.state;

    const filteredDashboardList = dashboardList.filter(
      ({ gender }) => selectedGender === String(gender),
    );

    return (
      <div className="pst-newPresentation">
        {!isRussianLanguage() && <div className="pst-newPresentation__title">
          <Text level="3" template="default" weight={800} inline>
            {t('dashboard.selectStory')}
          </Text>
        </div>}

        <div className="pst-presentationFilter">
          <div className="pst-presentationFilter__tagsWrapper">
            <div className="pst-presentationFilterTags pst-presentationFilterTags--top">
              <div className="pst-presentationFilterTags__tag pst-presentationFilterTags__tag--boy">
                <Button
                  template="select-small"
                  onClick={() => this.handleFilterGender('1')}
                  active={selectedGender === '1'}
                >
                  <span className="pst-presentationFilterTags__tagInner">
                    <Icon
                      name="Boy"
                      size="sm"
                      alternative
                      className="pst-presentationFilterTags__iconSvg"
                    />
                    {t('dashboard.forBoys')}
                  </span>
                </Button>
              </div>
              <div className="pst-presentationFilterTags__tag pst-presentationFilterTags__tag--girl">
                <Button
                  template="select-small"
                  onClick={() => this.handleFilterGender('0')}
                  active={selectedGender === '0'}
                >
                  <span className="pst-presentationFilterTags__tagInner">
                    <Icon
                      name="Girl"
                      size="sm"
                      alternative
                      className="pst-presentationFilterTags__iconSvg"
                    />
                    {t('dashboard.forGirls')}
                  </span>
                </Button>
              </div>
            </div>

            {!!tags.length && (
              <div className="pst-presentationFilterTags--bottom">
                <Collapser initHeight={50}>
                  <div className="pst-presentationCollapserContent">
                    {tags
                      && tags.map((item, index) => (
                        <div
                          className="pst-presentationFilterTags__tag"
                          key={index}
                        >
                          <Button
                            template="select-small"
                            onClick={() => this.handleFilterTag(item.title)}
                            active={selectedTags.includes(item.title)}
                          >
                            {item.title}
                          </Button>
                        </div>
                      ))}
                  </div>
                </Collapser>
              </div>
            )}
          </div>

          <div className="pst-newPresentationList">
            {filteredDashboardList.map(
              ({
                _id,
                title,
                description,
                tags: briefTags,
                questions,
                design,
                structure,
                gender,
                language,
                free,
                icon,
                sponsorIcon,
              }) => (
                <div
                  className="pst-newPresentationList__item"
                  onClick={() => this.handleClickPresentation({
                    _id,
                    questions,
                    design,
                    structure,
                    tags: briefTags,
                    title,
                    description,
                    gender,
                    language,
                  })
                  }
                  key={_id}
                >
                  <CardBrief
                    title={title}
                    description={description}
                    tags={briefTags}
                    free={free}
                    icon={icon}
                    sponsorIcon={sponsorIcon}
                  />
                </div>
              ),
            )}
          </div>
        </div>
      </div>
    );
  }
}

NewPresentation.propTypes = {
  dashboardList: PropTypes.array,
  selectedTags: PropTypes.array,
  addSelectedTagFilter: PropTypes.func,
  removeSelectedTagFilter: PropTypes.func,
};

export default withTranslation()(NewPresentation);
