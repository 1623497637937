import React, { useEffect } from 'react';
// eslint-disable-next-line no-unused-vars
import '@app/services/MixpanelService';

import close from './close.png';
import './style.scss';
import './styles/index.css';
import './styles/customize-progress-bar.css';

import { AudioPlayerProvider } from './AudioPlayerContext';
import AudioPlayer from './AudioPlayer';

function RussianFairyTales({ toggle }) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  const handleClose = () => {
    document.body.style.overflow = 'unset';
    toggle();
  };

  return (<div className="pst-rft-chat">
    <a className='pst-rft-chat__close' href="#" onClick={handleClose}><img src={close}/></a>
    <AudioPlayerProvider>
      <AudioPlayer />
    </AudioPlayerProvider>
  </div>);
}

export default RussianFairyTales;
